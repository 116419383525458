@charset "UTF-8";
.page-index header .header-zhanwei {
  display: none;
}

.page-index header .header {
  background-color: transparent;
}

.page-index header .header .left img.white-logo {
  opacity: 1;
}

.page-index header .header .left img.green-logo {
  opacity: 0;
}

.page-index header .header .right .language:hover .title {
  color: #00aaa6;
}

.page-index header .header .right .language .title {
  color: #fff;
}

.page-index header .header .right a {
  color: #fff;
}

.page-index header .header .right a:hover, .page-index header .header .right a.active {
  color: #00aaa6;
}

.page-index .hamburger > div, .page-index .hamburger > div:after, .page-index .hamburger > div:before, .page-index .hamburger > view, .page-index .hamburger > view:after, .page-index .hamburger > view:before {
  background-color: #fff;
}

.page-index header.scroll-header .header {
  background-color: #fff;
}

.page-index header.scroll-header .header .left img.white-logo {
  opacity: 0;
}

.page-index header.scroll-header .header .left img.green-logo {
  opacity: 1;
}

.page-index header.scroll-header .header .right a {
  color: #666666;
}

.page-index header.scroll-header .header .right a.language {
  color: #999999;
}

.page-index header.scroll-header .header .right a:hover, .page-index header.scroll-header .header .right a.active {
  color: #00aaa6;
}

.page-index header.scroll-header .hamburger > div, .page-index header.scroll-header .hamburger > div:after, .page-index header.scroll-header .hamburger > div:before, .page-index header.scroll-header .hamburger > view, .page-index header.scroll-header .hamburger > view:after, .page-index header.scroll-header .hamburger > view:before {
  background-color: #00aaa6;
}

.index-banner {
  position: relative;
  height: 100vh;
}

@media (max-width: 1024px) {
  .index-banner {
    height: 600px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    height: 300px;
  }
}

.index-banner .swiper {
  height: 100%;
}

.index-banner .swiper .swiper-slide {
  position: relative;
}

.index-banner .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.index-banner .banner-text {
  position: absolute;
  z-index: 2;
  color: #fff;
  right: 100px;
  bottom: 36%;
}

@media (max-width: 1600px) {
  .index-banner .banner-text {
    right: 82px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text {
    right: 64px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text {
    right: 46px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text {
    right: 28px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text {
    right: 10px;
  }
}

.index-banner .banner-text .item {
  position: absolute;
  right: 0;
  bottom: 0;
  white-space: nowrap;
}

.index-banner .banner-text .item.active .t1, .index-banner .banner-text .item.active .t2 {
  opacity: 1;
  transform: translateX(0);
}

.index-banner .banner-text .item .t1 {
  transition: all .4s;
  font-family: 'hs-m';
  text-transform: uppercase;
  transform: translateX(-150px);
  opacity: 0;
  font-size: 80px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .index-banner .banner-text .item .t1 {
    font-size: 70px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text .item .t1 {
    font-size: 60px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text .item .t1 {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text .item .t1 {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text .item .t1 {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-text .item .t1 {
    margin-bottom: 13px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text .item .t1 {
    margin-bottom: 11px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text .item .t1 {
    margin-bottom: 9px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text .item .t1 {
    margin-bottom: 7px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text .item .t1 {
    margin-bottom: 5px;
  }
}

.index-banner .banner-text .item .t2 {
  font-family: 'hs-l';
  text-align: right;
  transition: all .3s;
  transform: translateX(-80px);
  opacity: 0;
  font-size: 36px;
}

@media (max-width: 1600px) {
  .index-banner .banner-text .item .t2 {
    font-size: 31.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text .item .t2 {
    font-size: 27.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text .item .t2 {
    font-size: 22.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text .item .t2 {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text .item .t2 {
    font-size: 14px;
  }
}

.index-banner .banner-page {
  position: absolute;
  z-index: 3;
  color: #fff;
  white-space: nowrap;
  right: 100px;
  top: 72%;
}

@media (max-width: 1600px) {
  .index-banner .banner-page {
    right: 82px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-page {
    right: 64px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-page {
    right: 46px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-page {
    right: 28px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-page {
    right: 10px;
  }
}

.index-banner .banner-page .item {
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  transition: all .3s;
  width: 15px;
  height: 15px;
}

@media (max-width: 1600px) {
  .index-banner .banner-page .item {
    width: 14px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-page .item {
    width: 13px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-page .item {
    width: 12px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-page .item {
    width: 11px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-page .item {
    width: 10px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-page .item {
    height: 14px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-page .item {
    height: 13px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-page .item {
    height: 12px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-page .item {
    height: 11px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-page .item {
    height: 10px;
  }
}

.index-banner .banner-page .item:not(:first-child) {
  margin-top: 25px;
}

@media (max-width: 1600px) {
  .index-banner .banner-page .item:not(:first-child) {
    margin-top: 22px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-page .item:not(:first-child) {
    margin-top: 19px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-page .item:not(:first-child) {
    margin-top: 16px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-page .item:not(:first-child) {
    margin-top: 13px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-page .item:not(:first-child) {
    margin-top: 10px;
  }
}

.index-banner .banner-page .item::after {
  content: '';
  height: 1px;
  width: 40px;
  background-color: #fff;
  transform-origin: right;
  position: absolute;
  top: 50%;
  right: 200%;
  transition: all .3s;
  transform: scaleX(0.5);
  opacity: .4;
}

.index-banner .banner-page .item span {
  position: absolute;
  top: -5%;
  right: calc(200% + 55px);
  transform: translateX(-15px);
  transition: all .3s;
  opacity: 0;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-banner .banner-page .item span {
    font-size: 16.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-page .item span {
    font-size: 15.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-page .item span {
    font-size: 14.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-page .item span {
    font-size: 13.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-page .item span {
    font-size: 12px;
  }
}

.index-banner .banner-page .item:hover::after {
  transform: scaleX(1);
}

.index-banner .banner-page .item:hover span {
  opacity: .4;
  transform: translateX(0);
}

.index-banner .banner-page .item.active {
  background-color: #fff;
}

.index-banner .banner-page .item.active::after {
  opacity: 1;
  transform: scaleX(1);
}

.index-banner .banner-page .item.active span {
  opacity: 1;
  transform: translateX(0);
}

.index-about {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
}

@media (max-width: 1600px) {
  .index-about {
    padding-top: 102px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    padding-top: 84px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-top: 66px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-top: 48px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about {
    padding-bottom: 102px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    padding-bottom: 84px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-bottom: 66px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-bottom: 48px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-bottom: 30px;
  }
}

.index-about .index-about-title {
  color: #000;
  font-family: 'hs-m';
  font-size: 106px;
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .index-about .index-about-title {
    font-size: 92.8px;
  }
}

@media (max-width: 1366px) {
  .index-about .index-about-title {
    font-size: 79.6px;
  }
}

@media (max-width: 1024px) {
  .index-about .index-about-title {
    font-size: 66.4px;
  }
}

@media (max-width: 991px) {
  .index-about .index-about-title {
    font-size: 53.2px;
  }
}

@media (max-width: 767px) {
  .index-about .index-about-title {
    font-size: 40px;
  }
}

@media (max-width: 1600px) {
  .index-about .index-about-title {
    margin-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  .index-about .index-about-title {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .index-about .index-about-title {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .index-about .index-about-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-about .index-about-title {
    margin-bottom: 20px;
  }
}

.index-about .common-circle {
  left: -7%;
  top: 30%;
}

@media (max-width: 767px) {
  .index-about .common-circle {
    top: -5%;
  }
}

.index-about .cont {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 991px) {
  .index-about .cont {
    display: block;
  }
}

.index-about .cont .left {
  flex-shrink: 0;
  color: #00aaa6;
  position: relative;
  font-size: 48px;
  width: 27.6%;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .index-about .cont .left {
    font-size: 42px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .left {
    font-size: 36px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .left {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .left {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .left {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .left {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index-about .cont .left {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .left {
    margin-bottom: 10px;
  }
}

.index-about .cont .left::after {
  content: '“';
  font-family: Arial;
  color: #b5eae8;
  height: 0;
  position: absolute;
  line-height: .5;
  right: 100%;
  bottom: 150%;
  font-size: 160px;
}

@media (max-width: 1600px) {
  .index-about .cont .left::after {
    right: 99.6%;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .left::after {
    right: 99.2%;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .left::after {
    right: 98.8%;
  }
}

@media (max-width: 991px) {
  .index-about .cont .left::after {
    right: 98.4%;
  }
}

@media (max-width: 767px) {
  .index-about .cont .left::after {
    right: 98%;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .left::after {
    bottom: 144%;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .left::after {
    bottom: 138%;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .left::after {
    bottom: 132%;
  }
}

@media (max-width: 991px) {
  .index-about .cont .left::after {
    bottom: 126%;
  }
}

@media (max-width: 767px) {
  .index-about .cont .left::after {
    bottom: 120%;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .left::after {
    font-size: 136px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .left::after {
    font-size: 112px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .left::after {
    font-size: 88px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .left::after {
    font-size: 64px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .left::after {
    font-size: 40px;
  }
}

.index-about .cont .right {
  flex-shrink: 0;
  width: 71%;
}

@media (max-width: 991px) {
  .index-about .cont .right {
    width: 100%;
  }
}

.index-about .cont .right ul {
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
}

@media (max-width: 767px) {
  .index-about .cont .right ul {
    display: block;
  }
}

.index-about .cont .right ul li {
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  width: 31.5%;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .index-about .cont .right ul li {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .right ul li {
    margin-bottom: 30px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .right ul li {
    margin-bottom: 25px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .right ul li {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .right ul li {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .right ul li {
    margin-bottom: 10px;
  }
}

.index-about .cont .right ul li:nth-child(1), .index-about .cont .right ul li:nth-child(2) {
  width: 48.5%;
}

@media (max-width: 767px) {
  .index-about .cont .right ul li:nth-child(1), .index-about .cont .right ul li:nth-child(2) {
    width: 100%;
  }
}

.index-about .cont .right ul li:hover .item-img::after {
  opacity: .7;
}

.index-about .cont .right ul li:hover .item-img b {
  transform: scale(1.1);
}

.index-about .cont .right ul li:hover .item-t .icon .iconfont {
  transform: translateX(-10px);
}

.index-about .cont .right ul li .item-img::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all .3s;
  opacity: 1;
}

.index-about .cont .right ul li .item-t {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  transition: all .3s;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-about .cont .right ul li .item-t {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .right ul li .item-t {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .right ul li .item-t {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .right ul li .item-t {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .right ul li .item-t {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .right ul li .item-t {
    padding-right: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .right ul li .item-t {
    padding-right: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .right ul li .item-t {
    padding-right: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .right ul li .item-t {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .right ul li .item-t {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .right ul li .item-t {
    padding-top: 9px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .right ul li .item-t {
    padding-top: 8px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .right ul li .item-t {
    padding-top: 7px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .right ul li .item-t {
    padding-top: 6px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .right ul li .item-t {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .right ul li .item-t {
    padding-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .right ul li .item-t {
    padding-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .right ul li .item-t {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .right ul li .item-t {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .right ul li .item-t {
    padding-bottom: 10px;
  }
}

.index-about .cont .right ul li .item-t .t .title {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 28px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .index-about .cont .right ul li .item-t .t .title {
    font-size: 26px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .right ul li .item-t .t .title {
    font-size: 24px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .right ul li .item-t .t .title {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .right ul li .item-t .t .title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .right ul li .item-t .t .title {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .right ul li .item-t .t .title {
    margin-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .right ul li .item-t .t .title {
    margin-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .right ul li .item-t .t .title {
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .right ul li .item-t .t .title {
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .right ul li .item-t .t .title {
    margin-bottom: 10px;
  }
}

.index-about .cont .right ul li .item-t .t .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-family: 'hs-l';
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index-about .cont .right ul li .item-t .t .desc {
    font-size: 18.8px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .right ul li .item-t .t .desc {
    font-size: 17.6px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .right ul li .item-t .t .desc {
    font-size: 16.4px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .right ul li .item-t .t .desc {
    font-size: 15.2px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .right ul li .item-t .t .desc {
    font-size: 14px;
  }
}

.index-about .cont .right ul li .item-t .icon {
  flex-shrink: 0;
  margin-left: 30px;
}

@media (max-width: 1600px) {
  .index-about .cont .right ul li .item-t .icon {
    margin-left: 26px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .right ul li .item-t .icon {
    margin-left: 22px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .right ul li .item-t .icon {
    margin-left: 18px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .right ul li .item-t .icon {
    margin-left: 14px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .right ul li .item-t .icon {
    margin-left: 10px;
  }
}

.index-about .cont .right ul li .item-t .icon .iconfont {
  display: inline-block;
  transition: transform .3s;
  font-size: 34px;
}

@media (max-width: 1600px) {
  .index-about .cont .right ul li .item-t .icon .iconfont {
    font-size: 31.2px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .right ul li .item-t .icon .iconfont {
    font-size: 28.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .right ul li .item-t .icon .iconfont {
    font-size: 25.6px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .right ul li .item-t .icon .iconfont {
    font-size: 22.8px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .right ul li .item-t .icon .iconfont {
    font-size: 20px;
  }
}

.index-about .cont .right ul .placeholder {
  width: 31.5%;
}

@media (max-width: 767px) {
  .index-about .cont .right ul .placeholder {
    width: 100%;
  }
}

.index-pro {
  position: relative;
  padding-bottom: 120px;
}

@media (max-width: 1600px) {
  .index-pro {
    padding-bottom: 102px;
  }
}

@media (max-width: 1366px) {
  .index-pro {
    padding-bottom: 84px;
  }
}

@media (max-width: 1024px) {
  .index-pro {
    padding-bottom: 66px;
  }
}

@media (max-width: 991px) {
  .index-pro {
    padding-bottom: 48px;
  }
}

@media (max-width: 767px) {
  .index-pro {
    padding-bottom: 30px;
  }
}

.index-pro .common-circle {
  right: -5%;
  bottom: 18%;
  width: 500px;
  height: 500px;
}

@media (max-width: 1024px) {
  .index-pro .common-circle {
    bottom: 50%;
  }
}

@media (max-width: 767px) {
  .index-pro .common-circle {
    bottom: 85%;
  }
}

@media (max-width: 1600px) {
  .index-pro .common-circle {
    width: 440px;
  }
}

@media (max-width: 1366px) {
  .index-pro .common-circle {
    width: 380px;
  }
}

@media (max-width: 1024px) {
  .index-pro .common-circle {
    width: 320px;
  }
}

@media (max-width: 991px) {
  .index-pro .common-circle {
    width: 260px;
  }
}

@media (max-width: 767px) {
  .index-pro .common-circle {
    width: 200px;
  }
}

@media (max-width: 1600px) {
  .index-pro .common-circle {
    height: 440px;
  }
}

@media (max-width: 1366px) {
  .index-pro .common-circle {
    height: 380px;
  }
}

@media (max-width: 1024px) {
  .index-pro .common-circle {
    height: 320px;
  }
}

@media (max-width: 991px) {
  .index-pro .common-circle {
    height: 260px;
  }
}

@media (max-width: 767px) {
  .index-pro .common-circle {
    height: 200px;
  }
}

.index-pro .index-about-title {
  color: #000;
  font-family: 'hs-m';
  text-align: right;
  font-size: 106px;
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .index-pro .index-about-title {
    font-size: 92.8px;
  }
}

@media (max-width: 1366px) {
  .index-pro .index-about-title {
    font-size: 79.6px;
  }
}

@media (max-width: 1024px) {
  .index-pro .index-about-title {
    font-size: 66.4px;
  }
}

@media (max-width: 991px) {
  .index-pro .index-about-title {
    font-size: 53.2px;
  }
}

@media (max-width: 767px) {
  .index-pro .index-about-title {
    font-size: 40px;
  }
}

@media (max-width: 1600px) {
  .index-pro .index-about-title {
    margin-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  .index-pro .index-about-title {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .index-pro .index-about-title {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .index-pro .index-about-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-pro .index-about-title {
    margin-bottom: 20px;
  }
}

.index-pro .cont {
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  display: flex;
}

@media (max-width: 1024px) {
  .index-pro .cont {
    display: block;
  }
}

.index-pro .cont .pro-title {
  white-space: nowrap;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  width: 45%;
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-title {
    display: none;
  }
}

.index-pro .cont .pro-title h2 {
  color: #cfedeb;
  position: relative;
  cursor: pointer;
  transition: all .3s;
  height: 75px;
  line-height: 75px;
  font-size: 34px;
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-title h2 {
    font-size: 30.4px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-title h2 {
    font-size: 26.8px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-title h2 {
    font-size: 23.2px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-title h2 {
    font-size: 19.6px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-title h2 {
    font-size: 16px;
  }
}

.index-pro .cont .pro-title h2::after {
  content: '';
  display: block;
  width: 0;
  background-color: #00aaa6;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all .3s;
  height: 6px;
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-title h2::after {
    height: 5.4px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-title h2::after {
    height: 4.8px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-title h2::after {
    height: 4.2px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-title h2::after {
    height: 3.6px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-title h2::after {
    height: 3px;
  }
}

.index-pro .cont .pro-title h2:hover {
  color: #00aaa6;
}

.index-pro .cont .pro-title h2.active {
  color: #00aaa6;
  font-size: 50px;
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-title h2.active {
    font-size: 43.6px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-title h2.active {
    font-size: 37.2px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-title h2.active {
    font-size: 30.8px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-title h2.active {
    font-size: 24.4px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-title h2.active {
    font-size: 18px;
  }
}

.index-pro .cont .pro-title h2.active::after {
  width: 100%;
}

.index-pro .cont .pro-cont {
  flex-shrink: 0;
  position: relative;
  height: 660px;
  width: 50%;
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont {
    height: 540px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont {
    height: auto;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont {
    width: 100%;
  }
}

.index-pro .cont .pro-cont .item {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all .3s;
  opacity: 0;
  visibility: hidden;
  margin-bottom: 0;
  position: absolute;
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item {
    visibility: visible;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item {
    position: static;
  }
}

.index-pro .cont .pro-cont .item.active {
  opacity: 1;
  visibility: visible;
}

.index-pro .cont .pro-cont .item .mob-title {
  color: #00aaa6;
  display: none;
  font-size: 34px;
  margin-bottom: 50px;
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .mob-title {
    display: block;
  }
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-cont .item .mob-title {
    font-size: 31.2px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont .item .mob-title {
    font-size: 28.4px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .mob-title {
    font-size: 25.6px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-cont .item .mob-title {
    font-size: 22.8px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .mob-title {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-cont .item .mob-title {
    margin-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont .item .mob-title {
    margin-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .mob-title {
    margin-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-cont .item .mob-title {
    margin-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .mob-title {
    margin-bottom: 20px;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper {
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .pro-swiper {
    height: 450px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper {
    height: auto;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper {
    display: block;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper .left {
  flex-shrink: 0;
  width: 55%;
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .left {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .left {
    margin-bottom: 15px;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper .left .swiper {
  height: 100%;
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .left .swiper {
    height: 300px;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper .left .swiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.index-pro .cont .pro-cont .item .pro-swiper .left .swiper .swiper-slide img {
  display: block;
  max-height: 100%;
  max-width: 100%;
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .left .swiper .swiper-slide img {
    max-width: 80%;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper .right {
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  width: 41%;
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right {
    display: block;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right {
    width: 100%;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont {
    display: none;
  }
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont {
    margin-top: 27px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont {
    margin-top: 21px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont {
    margin-top: 18px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont {
    margin-top: 15px;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li:nth-child(n+6) {
  display: none;
}

.index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li:not(:last-child) {
  margin-bottom: 35px;
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li:not(:last-child) {
    margin-bottom: 31px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li:not(:last-child) {
    margin-bottom: 27px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li:not(:last-child) {
    margin-bottom: 23px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li:not(:last-child) {
    margin-bottom: 19px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li:not(:last-child) {
    margin-bottom: 15px;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li .icon {
  flex-shrink: 0;
  margin-right: 15px;
  width: 38px;
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li .icon {
    margin-right: 14px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li .icon {
    margin-right: 13px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li .icon {
    margin-right: 12px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li .icon {
    margin-right: 11px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li .icon {
    margin-right: 10px;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li .icon img {
  display: block;
  width: 100%;
  height: auto;
}

.index-pro .cont .pro-cont .item .pro-swiper .right .desc-cont ul li .t {
  flex-grow: 1;
  font-family: 'hs-l';
  color: #999999;
}

.index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
  width: 100%;
  box-sizing: content-box;
  padding-right: 20px;
  padding-top: 30px;
  height: 120px;
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    padding-right: 18px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    padding-right: 16px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    padding-right: 14px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    padding-top: 30px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    padding-top: 30px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    height: 112px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    height: 104px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    height: 96px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    height: 88px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper {
    height: 80px;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  cursor: pointer;
}

.index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide.swiper-slide-thumb-active::after {
  transform: scale(1);
}

.index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
  content: '';
  display: block;
  background-color: #e8fcfb;
  position: absolute;
  top: -20%;
  right: -15%;
  z-index: -1;
  border-radius: 50%;
  transition: all .3s;
  transform-origin: center;
  transform: scale(0);
  width: 70px;
  height: 70px;
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
    width: 66px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
    width: 62px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
    width: 58px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
    width: 54px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
    width: 50px;
  }
}

@media (max-width: 1600px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
    height: 66px;
  }
}

@media (max-width: 1366px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
    height: 62px;
  }
}

@media (max-width: 1024px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
    height: 58px;
  }
}

@media (max-width: 991px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
    height: 54px;
  }
}

@media (max-width: 767px) {
  .index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide::after {
    height: 50px;
  }
}

.index-pro .cont .pro-cont .item .pro-swiper .right .swiper .swiper-slide img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.index-news {
  padding-bottom: 75px;
}

@media (max-width: 1600px) {
  .index-news {
    padding-bottom: 64px;
  }
}

@media (max-width: 1366px) {
  .index-news {
    padding-bottom: 53px;
  }
}

@media (max-width: 1024px) {
  .index-news {
    padding-bottom: 42px;
  }
}

@media (max-width: 991px) {
  .index-news {
    padding-bottom: 31px;
  }
}

@media (max-width: 767px) {
  .index-news {
    padding-bottom: 20px;
  }
}

.index-news .index-news-cont {
  padding-left: 100px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont {
    padding-left: 80px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont {
    padding-left: 60px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont {
    padding-left: 40px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont {
    padding-left: 20px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont {
    padding-left: 0px;
  }
}

.index-news .index-news-cont .news-title {
  color: #000;
  font-family: 'hs-m';
  font-size: 60px;
  margin-bottom: 45px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .news-title {
    font-size: 56px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .news-title {
    font-size: 52px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .news-title {
    font-size: 48px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .news-title {
    font-size: 44px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .news-title {
    font-size: 40px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .news-title {
    margin-bottom: 39px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .news-title {
    margin-bottom: 33px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .news-title {
    margin-bottom: 27px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .news-title {
    margin-bottom: 21px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .news-title {
    margin-bottom: 15px;
  }
}

.index-news .index-news-cont .cont {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont {
    display: block;
  }
}

.index-news .index-news-cont .cont .left {
  flex-shrink: 0;
  border-radius: 20px;
  overflow: hidden;
  width: 40.24%;
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .left {
    width: 100%;
  }
}

.index-news .index-news-cont .cont .left:hover .item-img b {
  transform: scale(1.08);
}

.index-news .index-news-cont .cont .left .item-t {
  background-color: #00aaa6;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-top: 26px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-top: 22px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-top: 18px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-top: 14px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-bottom: 26px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-left: 34px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-left: 28px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-left: 22px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-left: 16px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-right: 34px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-right: 28px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-right: 22px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .left .item-t {
    padding-right: 10px;
  }
}

.index-news .index-news-cont .cont .left .item-t .title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-family: 'hs-m';
  line-height: 1.5;
  font-size: 26px;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .left .item-t .title {
    font-size: 24px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .left .item-t .title {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .left .item-t .title {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .left .item-t .title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .left .item-t .title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .left .item-t .title {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .left .item-t .title {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .left .item-t .title {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .left .item-t .title {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .left .item-t .title {
    margin-bottom: 10px;
  }
}

.index-news .index-news-cont .cont .left .item-t .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 1.5;
  font-size: 18px;
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .left .item-t .desc {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .left .item-t .desc {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .left .item-t .desc {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .left .item-t .desc {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .left .item-t .desc {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .left .item-t .desc {
    margin-bottom: 22px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .left .item-t .desc {
    margin-bottom: 19px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .left .item-t .desc {
    margin-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .left .item-t .desc {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .left .item-t .desc {
    margin-bottom: 10px;
  }
}

.index-news .index-news-cont .cont .left .item-t .date span {
  display: inline-block;
  background-color: #fff;
  color: #00aaa6;
  font-family: 'hs-l';
  border-radius: 3px;
  font-size: 14px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 6px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .left .item-t .date span {
    font-size: 13.6px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .left .item-t .date span {
    font-size: 13.2px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .left .item-t .date span {
    font-size: 12.8px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .left .item-t .date span {
    font-size: 12.4px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .left .item-t .date span {
    font-size: 12px;
  }
}

.index-news .index-news-cont .cont .right {
  flex-shrink: 0;
  margin-top: 9;
  width: 52.74%;
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right {
    margin-top: 15px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right {
    width: 100%;
  }
}

.index-news .index-news-cont .cont .right ul li {
  border-bottom: 1px solid #d5d5d5;
  padding-left: 20px;
  padding-right: 40px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right ul li {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right ul li {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right ul li {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right ul li {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right ul li {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right ul li {
    padding-right: 34px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right ul li {
    padding-right: 28px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right ul li {
    padding-right: 22px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right ul li {
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right ul li {
    padding-right: 10px;
  }
}

.index-news .index-news-cont .cont .right ul li:first-child {
  border-top: 1px solid #d5d5d5;
}

.index-news .index-news-cont .cont .right ul li:hover .title, .index-news .index-news-cont .cont .right ul li:hover .desc {
  color: #00aaa6;
}

.index-news .index-news-cont .cont .right ul li a {
  display: block;
  padding-top: 25px;
  padding-bottom: 15px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right ul li a {
    padding-top: 23px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right ul li a {
    padding-top: 21px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right ul li a {
    padding-top: 19px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right ul li a {
    padding-top: 17px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right ul li a {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right ul li a {
    padding-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right ul li a {
    padding-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right ul li a {
    padding-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right ul li a {
    padding-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right ul li a {
    padding-bottom: 10px;
  }
}

.index-news .index-news-cont .cont .right ul li .date span {
  display: inline-block;
  background-color: #00aaa6;
  color: #fff;
  font-family: 'hs-l';
  border-radius: 3px;
  font-size: 14px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 6px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right ul li .date span {
    font-size: 13.6px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right ul li .date span {
    font-size: 13.2px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right ul li .date span {
    font-size: 12.8px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right ul li .date span {
    font-size: 12.4px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right ul li .date span {
    font-size: 12px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right ul li .date span {
    margin-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right ul li .date span {
    margin-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right ul li .date span {
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right ul li .date span {
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right ul li .date span {
    margin-bottom: 10px;
  }
}

.index-news .index-news-cont .cont .right ul li .title {
  transition: all .3s;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #000;
  font-family: 'hs-m';
  font-size: 22px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right ul li .title {
    font-size: 20.8px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right ul li .title {
    font-size: 19.6px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right ul li .title {
    font-size: 18.4px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right ul li .title {
    font-size: 17.2px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right ul li .title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right ul li .title {
    margin-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right ul li .title {
    margin-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right ul li .title {
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right ul li .title {
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right ul li .title {
    margin-bottom: 10px;
  }
}

.index-news .index-news-cont .cont .right ul li .desc {
  transition: all .3s;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #999999;
  line-height: 1.5;
}

.index-news .index-news-cont .cont .right .more {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right .more {
    margin-top: 31px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right .more {
    margin-top: 27px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right .more {
    margin-top: 23px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right .more {
    margin-top: 19px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right .more {
    margin-top: 15px;
  }
}

.index-news .index-news-cont .cont .right .more a {
  display: flex;
  align-items: center;
  border: 1px solid #00aaa6;
  border-radius: 10px;
  color: #00aaa6;
  transition: all .3s;
  font-size: 22px;
  padding-left: 20px;
  padding-right: 15px;
  padding-top: 11px;
  padding-bottom: 8px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right .more a {
    font-size: 20.8px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right .more a {
    font-size: 19.6px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right .more a {
    font-size: 18.4px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right .more a {
    font-size: 17.2px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right .more a {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-right: 14px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-right: 13px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-right: 12px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-right: 11px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-top: 11px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-top: 11px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-top: 11px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-top: 11px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-top: 11px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-bottom: 8px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-bottom: 8px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-bottom: 8px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right .more a {
    padding-bottom: 8px;
  }
}

.index-news .index-news-cont .cont .right .more a:hover {
  background-color: #00aaa6;
  color: #fff;
}

.index-news .index-news-cont .cont .right .more a .iconfont {
  margin-left: 10px;
  font-size: 24px;
}

@media (max-width: 1600px) {
  .index-news .index-news-cont .cont .right .more a .iconfont {
    font-size: 22.8px;
  }
}

@media (max-width: 1366px) {
  .index-news .index-news-cont .cont .right .more a .iconfont {
    font-size: 21.6px;
  }
}

@media (max-width: 1024px) {
  .index-news .index-news-cont .cont .right .more a .iconfont {
    font-size: 20.4px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-cont .cont .right .more a .iconfont {
    font-size: 19.2px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-cont .cont .right .more a .iconfont {
    font-size: 18px;
  }
}
