@import "../../assets/styles/utils";

.page-index{
  header .header-zhanwei{
    display: none;
  }
  header .header{
    background-color: transparent;
  }
  header .header .left img.white-logo{
    opacity: 1;
  }
  header .header .left img.green-logo{
    opacity: 0;
  }
  header .header .right .language:hover .title{
    color: $color-main;
  }
  header .header .right .language .title{
    color: #fff;
  }
  header .header .right a{
    color: #fff;
    &:hover,&.active{
      color: $color-main;
    }
  }
  .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
    background-color: #fff;
  }
  header{
    &.scroll-header{
      .header{
        background-color: #fff;
        .left img.white-logo{
          opacity: 0;
        }
        .left img.green-logo{
          opacity: 1;
        }
        .right a{
          color: $color-text;
          &.language{
            color: $color-desc;
          }
          &:hover,&.active{
            color: $color-main;
          }
        }
      }
      .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
        background-color: $color-main;
      }
    }
  }
}

.index-banner{
  position: relative;
  @include res(height, 100vh,(md: 600px,sm:500px,xs:300px));
  .swiper{
    height: 100%;
    .swiper-slide{
      position: relative;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
  .banner-text{
    position: absolute;
    z-index: 2;
    color: #fff;
    @include res(right, 100px, 10 / 100);
    @include res(bottom,36%);
    .item{
      position: absolute;
      right: 0;
      bottom: 0;
      white-space: nowrap;
      &.active{
        .t1,.t2{
          opacity: 1;
          transform: translateX(0);
        }
      }
      .t1{
        transition: all .4s;
        font-family: 'hs-m';
        text-transform: uppercase;
        transform: translateX(-150px);
        opacity: 0;
        @include res(font-size, 80px, 30 / 80);
        @include res(margin-bottom,15px, 5 / 15);
      }
      .t2{
        font-family: 'hs-l';
        text-align: right;
        transition: all .3s;
        transform: translateX(-80px);
        opacity: 0;
        @include res(font-size, 36px, 14 / 36);
      }
    }
  }
  .banner-page{
    position: absolute;
    z-index: 3;
    color: #fff;
    white-space: nowrap;
    @include res(right, 100px, 10 / 100);
    @include res(top,72%);
    .item{
      cursor: pointer;
      position: relative;
      border-radius: 50%;
      background-color: rgba(255,255,255,.4);
      transition: all .3s;
      @include res(width,15px, 10 / 15);
      @include res(height,15px, 10 / 15);
      &:not(:first-child){
        @include res(margin-top, 25px, 10 / 25);
      }
      &::after{
        content: '';
        height: 1px;
        width: 40px;
        background-color: #fff;
        transform-origin: right;
        position: absolute;
        top: 50%;
        right: 200%;
        transition: all .3s;
        transform: scaleX(.5);
        opacity: .4;
      }
      span{
        position: absolute;
        top: -5%;
        right: calc(200% + 55px);
        transform: translateX(-15px);
        transition: all .3s;
        opacity: 0;
        @include res(font-size, 18px, 12 / 18);
      }
      &:hover{
        &::after{
          transform: scaleX(1);
        }
        span{
          opacity: .4;
          transform: translateX(0);
        }
      }
      &.active{
        background-color: #fff;
        &::after{
          opacity: 1;
          transform: scaleX(1);
        }
        span{
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
}

.index-about{
  position: relative;
  @include res(padding-top,120px, 30 / 120);
  @include res(padding-bottom,120px, 30 / 120);
  .index-about-title{
    color: #000;
    font-family: 'hs-m';
    @include res(font-size,106px, 40 / 106);
    @include res(margin-bottom, 70px, 20 / 70);
  }
  .common-circle{
    left: -7%;
    @include res(top,30%,(xs: -5%));
  }
  .cont{
    align-items: center;
    justify-content: space-between;
    @include res(display,flex,(sm:block));
    .left{
      flex-shrink: 0;
      color: $color-main;
      position: relative;
      @include res(font-size, 48px, 18 / 48);
      @include res(width,27.6%,(sm:100%));
      @include res(margin-bottom,0,(sm:20px,xs:10px));
      &::after{
        content: '“';
        font-family: Arial;
        color: #b5eae8;
        height: 0;
        position: absolute;
        line-height: .5;
        @include res(right, 100%, 98 / 100);
        @include res(bottom, 150%, 120 / 150);
        @include res(font-size,160px, 40 / 160);
      }
    }
    .right{
      flex-shrink: 0;
      @include res(width,71%,(sm:100%));
      ul{
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        @include res(display,flex,(xs:block));
        li{
          flex-shrink: 0;
          overflow: hidden;
          position: relative;
          @include res(border-radius, 20px);
          @include res(width,31.5%,(xs:100%));
          @include res(margin-bottom,35px, 10 / 35);
          &:nth-child(1),&:nth-child(2){
            @include res(width,48.5%,(xs:100%));
          }
          &:hover{
            .item-img{
              &::after{
                opacity: .7;
              }
              b{
                transform: scale(1.1);
              }
            } 
            .item-t{
              // color: $color-main;
              .icon .iconfont{
                transform: translateX(-10px);
              }
            }
          }
          .item-img{
            &::after{
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background-color: rgba(0,0,0,.25);
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              transition: all .3s;
              opacity: 1;
            }
          }
          .item-t{
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: 2;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            transition: all .3s;
            @include res(padding-left, 20px, 10 / 20);
            @include res(padding-right, 20px, 10 / 20);
            @include res(padding-top, 10px, 5 / 10);
            @include res(padding-bottom, 20px, 10 / 20);
            .t{
              .title{
                text-transform: uppercase;
                white-space: nowrap;
                @include res(font-size, 28px, 18 / 28);
                @include res(margin-bottom,15px, 10 / 15);
              }
              .desc{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                font-family: 'hs-l';
                @include res(font-size, 20px, 14 / 20);
              }
            }
            .icon{
              flex-shrink: 0;
              @include res(margin-left,30px, 10 / 30);
              .iconfont{
                display: inline-block;
                transition: transform .3s;
                @include res(font-size,34px, 20 / 34);
              }
            }
          }
        }
        .placeholder{
          @include res(width,31.5%,(xs:100%));
        }
      }
    }
  }
}

.index-pro{
  position: relative;
  @include res(padding-bottom,120px, 30 / 120);
  .common-circle{
    right: -5%;
    @include res(bottom,18%,(md:50%,xs:85%));
    @include res(width, 500px, 200 / 500);
    @include res(height, 500px, 200 / 500);
  }
  .index-about-title{
    color: #000;
    font-family: 'hs-m';
    text-align: right;
    @include res(font-size,106px, 40 / 106);
    @include res(margin-bottom, 70px, 20 / 70);
  }
  .cont{
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    @include res(display,flex,(md:block));
    .pro-title{
      white-space: nowrap;
      flex-shrink: 0;
      flex-direction: column;
      align-items: flex-end;
      @include res(display,flex,(md:none));
      @include res(width,45%);
      h2{
        color: #cfedeb;
        position: relative;
        cursor: pointer;
        transition: all .3s;
        @include res(height, 75px);
        @include res(line-height, 75px);
        @include res(font-size, 34px, 16 / 34);
        &::after{
          content: '';
          display: block;
          width: 0;
          background-color: $color-main;
          position: absolute;
          bottom: 0;
          right: 0;
          transition: all .3s;
          @include res(height,6px, 3 / 6);
        }
        &:hover{
          color: $color-main;
        }
        &.active{
          color: $color-main;
          @include res(font-size,50px, 18 / 50);
          &::after{
            width: 100%;
          }
        }
      }
    }
    .pro-cont{
      flex-shrink: 0;
      position: relative;
      @include res(height,660px,(mmd:540px,md:auto));
      @include res(width,50%,(md:100%));
      .item{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all .3s;
        @include res(opacity,0,(md:1));
        @include res(visibility,hidden,(md:visible));
        @include res(margin-bottom,0,(md:50px));
        @include res(position,absolute,(md:static));
        &.active{
          opacity: 1;
          visibility: visible;
        }
        .mob-title{
          color: $color-main;
          @include res(display,none,(md:block));
          @include res(font-size, 34px, 20 / 34);
          @include res(margin-bottom, 50px, 20 / 50);
        }
        .pro-swiper{
          align-items: stretch;
          justify-content: space-between;
          @include res(height, 100%,(md: 450px,xs:auto));
          @include res(display,flex,(xs:block));
          .left{
            flex-shrink: 0;
            @include res(width,55%,(xs:100%));
            @include res(margin-bottom,null,(xs:15px));
            .swiper{
              @include res(height,100%,(xs:300px));
              .swiper-slide{
                display: flex;
                align-items: center;
                justify-content: center;
                height: auto;
                img{
                  display: block;
                  max-height: 100%;
                  @include res(max-width, 100%,(xs:80%));
                }
              }
            }
          }
          .right{
            flex-shrink: 0;
            flex-direction: column;
            justify-content: space-between;
            @include res(display,flex,(xs:block));
            @include res(width,41%,(xs:100%));
            .desc-cont{
              @include res(display,null,(xs:none));
              @include res(margin-top,30px, 15 / 30);
              ul{
                li{
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  &:nth-child(n+6){
                    display: none;
                  }
                  &:not(:last-child){
                    @include res(margin-bottom,35px, 15 / 35);
                  }
                  .icon{
                    flex-shrink: 0;
                    @include res(margin-right,15px, 10 / 15);
                    @include res(width,38px);
                    img{
                      display: block;
                      width: 100%;
                      height: auto;
                    }
                  }
                  .t{
                    flex-grow: 1;
                    font-family: 'hs-l';
                    color: $color-desc;
                  }
                }
              }
            }
            .swiper{
              width: 100%;
              box-sizing: content-box;
              @include res(padding-right, 20px, 10 / 20);
              @include res(padding-top, 30px, 30 / 30);
              @include res(height,120px, 80 / 120);
              .swiper-slide{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                height: auto;
                cursor: pointer;
                &.swiper-slide-thumb-active{
                  &::after{
                    transform: scale(1);
                  }
                }
                &::after{
                  content: '';
                  display: block;
                  background-color: #e8fcfb;
                  position: absolute;
                  top: -20%;
                  right: -15%;
                  z-index: -1;
                  border-radius: 50%;
                  transition: all .3s;
                  transform-origin: center;
                  transform: scale(0);
                  @include res(width,70px, 50 / 70);
                  @include res(height,70px, 50 / 70);
                }
                img{
                  display: block;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.index-news{
  @include res(padding-bottom, 75px, 20 / 75);
  .index-news-cont{
    @include res(padding-left, 100px, 0 / 100);
    .news-title{
      color: #000;
      font-family: 'hs-m';
      @include res(font-size, 60px, 40 / 60);
      @include res(margin-bottom, 45px, 15 / 45);
    }
    .cont{
      align-items: flex-start;
      justify-content: space-between;
      @include res(display,flex,(sm:block));
      .left{
        flex-shrink: 0;
        border-radius: 20px;
        overflow: hidden;
        @include res(width, 40.24%,(sm:100%));
        &:hover{
          .item-img b{
            transform: scale(1.08);
          }
        }
        .item-t{
          background-color: $color-main;
          color: #fff;
          @include res(padding-top, 30px, 10 / 30);
          @include res(padding-bottom, 30px, 10 / 30);
          @include res(padding-left, 40px, 10 / 40);
          @include res(padding-right, 40px, 10 / 40);
          .title{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden; 
            font-family: 'hs-m';
            line-height: 1.5;
            @include res(font-size, 26px, 16 / 26);
            @include res(margin-bottom,20px, 10 / 20);
          }
          .desc{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden; 
            line-height: 1.5;
            @include res(font-size, 18px, 14 / 18);
            @include res(margin-bottom,25px, 10 / 25);
          }
          .date{
            span{
              display: inline-block;
              background-color: #fff;
              color: $color-main;
              font-family: 'hs-l';
              border-radius: 3px;
              @include res(font-size, 14px, 12 / 14);
              @include res(padding-left, 14px);
              @include res(padding-right, 14px);
              @include res(padding-top, 6px);
            }
          }
        }
      }
      .right{
        flex-shrink: 0;
        @include res(margin-top,9,(sm:30px,xs:15px));
        @include res(width, 52.74%,(sm:100%));
        ul{
          li{
            border-bottom: 1px solid $color-line;
            @include res(padding-left,20px, 10 / 20);
            @include res(padding-right,40px, 10 / 40);
            &:first-child{
              border-top: 1px solid $color-line;
            }
            &:hover{
              .title,.desc{
                color: $color-main;
              }
            }
            a{
              display: block;
              @include res(padding-top,25px, 15 / 25);
              @include res(padding-bottom,15px, 10 / 15);
            }
            .date{
              span{
                display: inline-block;
                background-color: $color-main;
                color: #fff;
                font-family: 'hs-l';
                border-radius: 3px;
                @include res(font-size, 14px, 12 / 14);
                @include res(padding-left, 14px);
                @include res(padding-right, 14px);
                @include res(padding-top, 6px);
                @include res(margin-bottom,15px, 10 / 15);
              }
            }
            .title{
              transition: all .3s;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              color: #000;
              font-family: 'hs-m';
              @include res(font-size, 22px, 16 / 22);
              @include res(margin-bottom,15px, 10 / 15);
            }
            .desc{
              transition: all .3s;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              color: $color-desc;
              line-height: 1.5;
            }
          }
        }
        .more{
          display: flex;
          justify-content: flex-end;
          @include res(margin-top,35px, 15 / 35);
          a{
            display: flex;
            align-items: center;
            border: 1px solid $color-main;
            border-radius: 10px;
            color: $color-main;
            transition: all .3s;
            @include res(font-size, 22px, 16 / 22);
            @include res(padding-left,20px, 10 / 20);
            @include res(padding-right,15px, 10 / 15);
            @include res(padding-top,11px, 11 / 11);
            @include res(padding-bottom,8px, 8 / 8);
            &:hover{
              background-color: $color-main;
              color: #fff;
            }
            .iconfont{
              margin-left: 10px;
              @include res(font-size, 24px, 18 / 24);
            }
          }
        }
      }
    }
  }
}